/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.clickable {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.mobile-full-width {
  @media (max-width: 770px) {
    width: 100%;
  }
}

.p-inputtext {
  width: 100%;
}

.user-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.p-carousel-items-container {
  align-items: center;
  text-align: -moz-center;
  text-align: -webkit-center;
}

.p-link {
  border-radius: 50%;
}

.p-carousel-indicator .p-link {
  width: 15px;
  height: 15px;
}

.text-overflow-elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story-type-switch {
  div .p-inputswitch-slider {
    background-color: #e7267a;
  }

  .p-inputswitch-checked .p-inputswitch-slider {
    background-color: #6fc7da;
  }
}

.p-fileupload-content {
  min-height: 150px;
}

.p-datepicker-month {
  margin-right: 60px;
}

.submit-button button {
  background-color: #89cfcf;
  border: none;
}

.button-full-width button {
  width: 100%;
}

// Used for paginator
.p-paginator-pages .p-link {
  width: 45px;
  height: 45px;
}

vg-fullscreen {
  margin-left: auto;
}

vg-player {
  border-radius: 15px;
}

.button-success {
  button {
    background-color: #89cfcf;
    border: none;
  }
}

.users-table table {
  border-spacing: 0 1em !important;
  border-collapse: separate !important;

  tr {
    cursor: pointer;
  }

  tbody td {
    border: unset;
  }

  tr td:first-child {
    border-radius: 15px 0 0 15px;
  }

  tr td:last-child {
    border-radius: 0 15px 15px 0;
  }

  tr:hover {
    background-color: #dadddd;
  }
}

.user-profile-dialog {
  width: 800px;
  height: 650px;
  border-radius: 15px;
  box-shadow: none;

  .p-dialog-content {
    background-color: unset;
    border-radius: 15px;
    color: #ffffff;
    padding: 0px;
  }

  @media (max-width: 770px) {
    height: 80vh;
    max-width: 90vw;
  }
}

.close-user-profile-button {
  button {
    color: white;
  }
}

.notifications-dialog {
  width: 600px;
}

.notifications-custom-scrollbar {
  .p-scrollpanel-wrapper {
    border-right: 9px solid var(--layer-1);
  }

  .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    transition: background-color 0.2s;

    &:hover {
      background-color: #007ad9;
    }
  }
}

.surveys-dialog {
  width: 600px;

  @media (max-width: 770px) {
    width: 90vw;
  }

  .p-dialog-content::-webkit-scrollbar {
    width: 10px;
  }

  .p-dialog-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .p-dialog-content::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .p-dialog-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

staricon,
starfillicon {
  svg {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 700px) {
    width: 24px;
    height: 24px;
  }
}

p-rating {
  .p-rating {
    gap: 20px;
  }
}

.story-carousel-content .p-carousel-container {
  position: relative;

  .p-carousel-prev,
  .p-carousel-next {
    background-color: #eeeeee;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 10;
  }

  .p-carousel-prev {
    left: 0;
  }

  .p-carousel-next {
    right: 0;
  }
}

.pinned-stories-carousel-content .p-carousel-container {
  @media (max-width: 770px) {
    width: calc(100vw - 20px);
  }

  .p-carousel-prev,
  .p-carousel-next {
    background-color: #ffffff;
    width: 30px;
    height: 30px;
  }
}

.preview-story-dialog {
  width: 90vw;
  height: 90vh;
  border-radius: 15px;
  box-shadow: none;

  .p-dialog-content {
    background-color: unset;
    border-radius: 15px;
    padding: 0px;
    position: relative;
  }

  @media (max-width: 850px) {
    width: 100vw;
    max-height: calc(80vh - 10px);
    margin-top: 10px;

    .p-dialog-content::-webkit-scrollbar {
      width: 10px;
    }

    .p-dialog-content::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .p-dialog-content::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    .p-dialog-content::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.preview-story-carousel-content
  .p-carousel-container
  .p-carousel-items-content
  .p-carousel-items-container
  .p-carousel-item {
  max-width: 100%;
  max-height: 100%;
}

p-carousel .p-carousel {
  max-height: 100%;
}

.story-info-scroll-panel .p-scrollpanel {
  padding-bottom: 135px;
  height: 100%;
}

.post-comment-dialog .p-dialog-content {
  display: none;
}

.select-group-dialog .p-dialog-content {
  width: 100%;
  max-width: 90vw;
}

.p-accordion-header a {
  background-color: white;
}

// Stars rating
.p-rating .p-rating-icon.p-rating-icon-active,
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #89cfcf;
}

.p-toast {
  max-width: 90vw;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #89cfcf;
  background: #89cfcf;
}

.story-likes-table
  .p-datatable-wrapper
  .p-datatable-table
  .p-datatable-tbody
  tr:last-child
  td {
  border: none;
}

.kudos-dialog {
  width: 400px;
  max-width: 90vw;
}

.p-menu-overlay {
  padding: 10px;
}

.p-menuitem-content {
  border-radius: 10px;
}

mark {
  background: rgba(89, 207, 207, 0.4);
}

.story-preview mention-list {
  left: -50px !important;
}

.hide-on-mobile {
  @media (max-width: 770px) {
    display: none;
  }
}

.mobile-groups-scrollpanel {
  .p-scrollpanel-bar {
    display: none;
  }

  .p-scrollpanel-wrapper .p-scrollpanel-content {
    height: 100%;
    width: 100vw;
    overflow-y: hidden;
    background-color: #f2f2f2;
  }
}

.groups-table .p-datatable-wrapper table {
  max-width: 100%;
}

.p-datatable .p-datatable-tbody > tr > td {
  @media (max-width: 770px) {
    line-break: anywhere;
  }
}

.p-paginator-last {
  @media (max-width: 770px) {
    display: none;
  }
}

.p-paginator-first {
  @media (max-width: 770px) {
    display: none;
  }
}

.p-paginator-pages {
  @media (max-width: 770px) {
    button:not(.p-highlight) {
      display: none;
    }
  }
}

.center-on-mobile {
  @media (max-width: 770px) {
    text-align: center;
    justify-content: center;
  }
}

.important-story-dialog {
  width: 500px;
  height: 700px;
  border-radius: 15px;
  box-shadow: none;
  max-height: 80vh;

  .p-dialog-content {
    border-radius: 15px 15px 0px 0px;
    padding: 0px;
  }

  .p-dialog-footer {
    border-radius: 0px 0px 15px 15px;
    padding: 5px;
  }

  @media (max-width: 770px) {
    height: 80vh;
    max-width: 90vw;
  }
}

.confirm-read-important-story {
  background-color: #e7267a;
  border: none;
}

.unpin-story-button {
  height: 30px;
  width: 30px;
}

.comment-image {
  max-width: 100px;
  max-height: 100px;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .p-image-toolbar button:not(:last-child) {
    display: none;
  }

  div:has(.p-image-preview) {
    width: 100%;
    height: 100%;
    padding: 20px;

    .p-image-preview {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.mention-dropdown li {
  cursor: pointer;
}

.mention-dropdown li:hover {
  background-color: #d8d8d8;
}

.answers {
  p-checkbox label {
    line-break: anywhere;
  }

  p-radiobutton label {
    line-break: anywhere;
  }
}

.mobile-pinned-stories-scrollpanel {
  .p-scrollpanel-bar {
    display: none;
  }

  .p-scrollpanel-wrapper .p-scrollpanel-content {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    background-color: #f2f2f2;

    display: flex;
    flex-direction: row;
    gap: 10px;

    padding-right: 0px;
  }
}

.edit-comment-dialog .p-dialog-content {
  width: 600px;
  max-width: 90vw;
  border-radius: 0px;
  padding: 30px;
  background-color: #ffffff;
  overflow: hidden;

  mention-list {
    left: 0px !important;

    ul {
      height: 150px !important;
    }
  }
}

.terms-and-conditions-dialog {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 90vh;

  .p-dialog-content {
    overflow-y: hidden;
    position: relative;
  }

  @media (max-width: 900px) {
    max-width: 90vw;
  }
}

.p-progressbar-value-animate {
  background-color: #6fc7da;
  border-color: #6fc7da;
}

.question-time-limit .p-progressbar-value-animate {
  background-color: #e7267a;
  border-color: #e7267a;
}

.documents-table table {
  border-spacing: 0 4px !important;
  border-collapse: separate !important;

  tr {
    cursor: pointer;
    background-color: #f2f2f2;
  }

  tbody td {
    border: unset;
  }

  tr td:first-child {
    border-radius: 15px 0 0 15px;
  }

  tr td:last-child {
    border-radius: 0 15px 15px 0;
  }

  tr:hover {
    background-color: #dadddd;
  }
}

.p-datatable .p-paginator-bottom {
  border-width: 0px;
}

.ql-editor .mention {
  color: #2962ff;
  background-color: #e1f5fe;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
}

.ql-mention-list {
  background-color: white;
  list-style-type: none;
  transform: translateY(-2px);
  margin-top: 0;
  padding: 8px;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.ql-mention-list .ql-mention-list-item {
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 10px;
}

.ql-mention-list .ql-mention-list-item.selected {
  background-color: #89cfcf33;
}

// Quill editor colors
button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: #89cfcf !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: #ff2962 !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: #89cfcf !important;
  stroke: none;
}

.ql-active .ql-fill {
  fill: #ff2962 !important;
  stroke: none;
}

quill-editor {
  .ql-toolbar {
    border-radius: 15px 15px 0px 0px;
  }

  .ql-container {
    border-radius: 0px 0px 15px 15px;
    max-height: calc(100% - 65px);
  }
}

span.mention {
  cursor: pointer;
  color: #2962ff;
  background-color: #e1f5fe;
  padding: 4px;
  border-radius: 8px;
}
